<template>
  <!-- 云端答疑---讨论部分 -->
  <div class="Q-A-home" v-wechat-title="`${$route.meta.title}_京蒙教育协作智慧平台`">
    <div class="Q-A-home-wrapper wrapper">
      <!-- 评论主体 -->
      <div v-if="discussList && discussList.length > 0">
        <div class="comments-wrapper">
          <div class="comments">
            <!-- 一级留言 start-->
            <div class="one" v-if="pages.currentPage == 1 && initalCommen.level == 1">
              <div class="summary flex-align-between">
                <!-- 个人信息 -->
                <div class="personal-info flex-align f20">
                  <img class="avatar" :src="downloadURL+initalCommen.userAvatar" alt=""
                    :onerror="$store.state.user.defaultAvatar">
                  <div>
                    <p class="name">{{initalCommen.userName}}</p>
                    <p style="font-size:12px">{{initalCommen.createTime}}</p>
                  </div>
                </div>
                <!-- 右侧功能条 -->
                <div class="toolbar flex-layout">
                  <button class="btn" v-if="(initalCommen.userName === userInfo.name)"
                    @click="editCommen(initalCommen)">编辑</button>
                  <button class="btn" v-if="(initalCommen.userName === userInfo.name) || $store.state.user.isAdmin"
                    @click="deleteReply(initalCommen)">删除</button>
                  <button class="btn" v-if="(initalCommen.userName === userInfo.name) || $store.state.user.isAdmin"
                    @click="exportExcel()">导出</button>
                </div>
              </div>
              <!-- 留言内容 -->
              <div class="reply-content">{{initalCommen.content}}</div>
              <!-- 留言回复框 -->
              <div class="textarea-container parent-textarea">
                <el-input type="textarea" :rows="5" placeholder="回复：" resize="none" v-model="oneLevelTextArea"
                  :maxlength="255" show-word-limit class="textarea">
                </el-input>
                <div class="control-textarea">
                  <el-button type="primary" @click="submitReply(initalCommen)">回 复</el-button>
                </div>
              </div>
            </div>
            <!-- 一级留言 end-->
            <div class="twoLevelComment" v-for="(item) in discussList" :key="item.id">
              <discussMain :comment="item" @replay="isReload()"></discussMain>
            </div>
          </div>
        </div>
        <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
          :limit.sync="pages.pageSize" :pageSizes="[5,10,15]" @pagination="handlePageChange">
        </pagination>
      </div>
      <!-- 无留言 -->
      <Empty :show="discussList && discussList.length === 0" :text="'暂无提问'"></Empty>
      <!-- 编辑留言 -->
      <el-dialog :visible.sync="displayEditDialog" top="30vh" width="50%">
        <div slot="title" class="dialog-title flex-align">
          <img :src="downloadURL + userInfo.avatar" alt="" :onerror="$store.state.user.defaultAvatar"
            class="dialog-avatar">
          <p>{{userInfo.name}}</p>
        </div>
        <el-input type="textarea" :rows="5" resize="none" placeholder="写提问" v-model="editTextArea" :maxlength="255"
          show-word-limit>
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" :plain="true" @click="edit">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import discussMain from "./discuss_area";
  export default {
    inject: ['reload'],
    components: {
      discussMain
    },
    data() {
      return {
        userInfo: {}, //个人信息
        discussList: [], //讨论列表
        displayEditDialog: false, //编辑弹窗状态
        editId: 0, // 编辑项ID
        editTextArea: '', //编辑项内容
        oneLevelTextArea: '', //一级回复文本框内容
        childTextArea: '',
        threeLevelTextArea: '',
        parentId: 0,
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 10,
        },
        initalCommen: {},
      }
    },
    methods: {
      // 获取详情列表
      async getDisscusDetail() {
        let params = {
          id: Number(this.$route.params.id),
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        let resData = await this.$Api.Course.getDisscusDetail(params);
        console.log(resData);
        if (JSON.stringify(resData.data.records) == '[]') return;
        this.discussList = resData.data.records;
        this.initalCommen = resData.data.records[0];
        this.pages.total = resData.data.total
        // 储存当前名字
        this.$route.meta.title = this.initalCommen.content
        this.$store.commit('breadPageNameChange', this.initalCommen.content)
      },
      isReload(value) {
        this.getDisscusDetail();
      },
      // 导出当页数据
      async exportExcel() {
        window.location.href = encodeURI(axios.defaults.baseURL +
          `/course/teach/comment/commentOneInfo/export?id=${this.initalCommen.id}`)
      },
      // 删除留言
      deleteReply(item) {
        let that = this;
        this.$confirm('删除该内容会导致所有回复内容都被删除，是否删除？（删除完成后将自动返回答疑列表页面）', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            background: 'rgba(255, 255, 255, 0.7)'
          });
          this.$Api.Course.commentDel(item.id).then((resData) => {
            if (resData.code === 200 && resData.msg === "请求成功") {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              setTimeout(() => {
                // this.reload();
                loading.close();
                that.$router.push(`/question/${that.$route.query.listId}`);
              }, 500)
            } else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 一级回复
      async submitReply(item) {
        console.log(item);
        if (!this.oneLevelTextArea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          courseId: Number(this.$route.query.listId),
          parentId: item.id,
          content: this.oneLevelTextArea,
        }
        console.log(params);
        let resData = await this.$Api.Course.createDisscus(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '回复成功',
            type: 'success'
          });
          setTimeout(() => {
            this.getDisscusDetail();
            this.oneLevelTextArea = '';
          }, 100)
        } else {
          this.$message.error(resData.msg);
        }
      },
      // 回复
      reply(item) {
        // console.log(item.id)
        this.$store.state.discuss.replyArea = item.id
      },
      // 二级回复
      async submitReplyLevelTwo(item) {
        console.log(item);
        if (!this.childTextArea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          courseId: Number(this.$route.query.listId),
          parentId: item.id,
          content: this.childTextArea,
        }
        console.log(params);
        let resData = await this.$Api.Course.createDisscus(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '回复成功',
            type: 'success'
          });
          setTimeout(() => {
            this.getDisscusDetail();
            this.childTextArea = '';
          }, 100)
        } else {
          this.$message.error(resData.msg);
        }
      },
      // 二级回复
      async submitReplyLevelThree(item) {
        console.log(item);
        if (!this.threeLevelTextArea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          courseId: Number(this.$route.query.listId),
          parentId: item.id,
          content: this.threeLevelTextArea,
        }
        console.log(params);
        let resData = await this.$Api.Course.createDisscus(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '回复成功',
            type: 'success'
          });
          setTimeout(() => {
            this.getDisscusDetail();
            this.threeLevelTextArea = '';
          }, 100)
        } else {
          this.$message.error(resData.msg);
        }
      },
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        scrollTo(0, 0)
        this.getDisscusDetail();
      },
      // 编辑
      editCommen(item) {
        this.editTextArea = item.content;
        this.editId = item.id;
        this.displayEditDialog = true;
      },
      // 编辑请求
      async edit() {
        if (!this.editTextArea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          id: this.editId,
          content: this.editTextArea,
        }
        console.log(params);
        let resData = await this.$Api.Course.editDisscus(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.displayEditDialog = false;
          this.getDisscusDetail()
        } else {
          this.$message.error(resData.msg);
        }
      },
    },
    mounted() {
      this.userInfo = this.$store.state.user.userInfo;
      this.getDisscusDetail();
    }
  }
</script>

<style lang="less" scoped>
  .Q-A-home {
    width: 990px;
    margin: 0 auto;

    .Q-A-home-wrapper {
      overflow: hidden;
      padding: 20px;
      background: #fff;
      
    }

    .dialog-title {
      .dialog-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 17px;
      }

      p {
        font-size: 16px;
      }
    }


    .comments-wrapper {
      border: 1px solid #F9F9F9;
      border-radius: 4px;
      margin: 0 auto 29px;

      .comments {
        padding: 20px 30px 26px 22px;

        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 11px;
        }

        .name {
          font-size: 12px;
          font-weight: 500;
        }

        .toolbar {
          flex-shrink: 0;
          padding-top: 8px;
          font-size: 12px;

          .btn {
            margin-left: 20px;
            font-size: 12px;
          }
        }

        .reply-content {
          margin: 15px auto;
          padding: 16px;
          min-height: 44px;

          color: #666;
          font-size: 16px;
          border-bottom: 1px solid #DCDFE6;
          white-space: pre-line;
          word-break: break-word;
          // &>pre {
          //   color: #666;
          //   font-size: 16px;
          //   font-family:"Microsoft YaHei";
          // }
        }


        .textarea-container {
          margin: 15px auto;

          .control-textarea {
            margin-top: 10px;
            text-align: right;

            /deep/ .el-button {
              font-size: 12px;
              padding: 8px 20px;
            }
          }

          .textarea {
            display: block;
            font-size: 16px;
          }
        }

        .btn {
          cursor: pointer;
        }
      }
    }

    @media screen and (min-width:1250px) {
      width: 1200px;
    }
  }
</style>